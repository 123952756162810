import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import {Badge, Row,Col} from 'reactstrap'
import { slugify, getStylefromString, fixNames } from '../util/utilityFunctions'
import '../styles/index.scss'

const tagsPage = ({ pageContext }) => {

    const {tags , tagPostCounts} = pageContext;
    
    let buttons = []


    tags.forEach(tag => {
        buttons.push(
            <h3 key={tag} style={{marginBottom: '10px'}}>
                <Col>
                        <Badge color={getStylefromString(tag)} pill href={`/tags/${slugify(tag)}`} >
                            {fixNames(tag)} <Badge color="light">{tagPostCounts[tag]}</Badge>
                        </Badge>
                        </Col>
                    </h3>
        )
    });

    return(
        <Layout pageTitle="All tags">
            <SEO title="All tags" keywords={['tags']}/>
            <Row>
                {buttons}
            </Row>
        </Layout>
    )
}



export default tagsPage